import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import config from '../../data/SiteConfig';
import { Social } from './Social';
import { Link } from 'gatsby';

export const AboutMe = ({
  hideTitle,
  horizontalPicture,
}: {
  hideTitle?: boolean;
  horizontalPicture?: boolean;
}) => {
  return (
    <div>
      <div className={`mx-auto py-16 ${!hideTitle && 'max-w-7xl px-4 sm:px-6 lg:px-8'} lg:py-24`}>
        <div
          className={`space-y-12 lg:grid ${!hideTitle && 'lg:grid-cols-3'} lg:gap-8 lg:space-y-0`}
        >
          {!hideTitle && (
            <div className="space-y-5 sm:space-y-4">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-white">
                About
              </h2>
            </div>
          )}
          <div className="flex flex-wrap lg:col-span-2">
            <ul
              role="list"
              className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0"
            >
              <li className="sm:py-8">
                <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 lg:gap-12 sm:space-y-0">
                  <div
                    className={`relative ${
                      horizontalPicture
                        ? 'xs:aspect-h-3 xs:aspect-w-3 md:aspect-h-2 xl:aspect-w-4 xl:aspect-h-2'
                        : 'sm:aspect-w-3 sm:aspect-h-4'
                    }`}
                  >
                    <div className="absolute border-blue-500 top-0 left-0 right-0 bottom-0 h-full -translate-x-2 -translate-y-2 sm:-translate-x-3 sm:-translate-y-3 md:-translate-x-5 md:-translate-y-5 rounded-lg opacity-30 border-2" />
                    <div className="object-cover grayscale overflow-hidden w-fit z-10 shadow-lg rounded-lg hover:-translate-x-5 hover:-translate-y-5 transition duration-500 hover:filter-none">
                      <StaticImage
                        src="../../static/logos/felix_gerschau_picture.jpg"
                        alt="Photo of myself with a computer"
                        placeholder="blurred"
                        objectFit="cover"
                        className="h-full rounded-lg"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="space-y-4">
                      <div className="text-lg leading-6 text-white font-medium space-y-1">
                        <h3>Felix Gerschau</h3>
                        <p className="text-blue-500">Software Developer</p>
                      </div>
                      <div className="text-lg text-gray-400">
                        <p className="mb-2">
                          I've been working as a software developer since 2017, mostly at small to
                          medium sized companies.
                        </p>
                        <p className="mb-2">
                          As many others, I acquired most of my programming knowledge online,
                          through blogs and free resources like this one.
                        </p>
                        <p className="mb-2">
                          I hope that with the articles on here, I can give part of the value I
                          received back to the community.
                        </p>
                        <p className="mb-2">
                          If you like my content, consider subscribing to my email list below. You
                          can also{' '}
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={config.social.email}
                            className="text-blue-500 hover:underline hover:text-blue-400"
                          >
                            send me an email
                          </a>{' '}
                          to say hi.
                        </p>
                      </div>
                      <Social />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            {!hideTitle && (
              <Link
                to="/about/"
                className="ml-auto text-gray-300 font-medium tracking-lighter py-3 px-6 ring-2 ring-slate-400 transition duration-300 hover:ring-blue-500/70 hover:text-blue-500 hover:bg-blue-500/10 rounded-xl text-base"
              >
                Read more
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
