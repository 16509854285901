import React from 'react';

export const ConnectionLine = ({
  className,
  lineClass,
}: {
  className?: string;
  lineClass?: string;
}) => {
  return (
    <div className={`flex z-10 flex-row flex-nowrap h-10 lg:h-16 bg-transparent ${className}`}>
      <div className={`flex-grow border-slate-700 border-r-2 h-10 lg:h-16 ${lineClass}`} />
      <div className="flex-grow" />
    </div>
  );
};
