import React, { useState } from 'react';
import { List } from '../../components/List';

const experienceEntries = [
  {
    company: 'Honeypot.io',
    position: 'Senior Software Developer',
    timeRange: 'October 2021 - Present',
    companyUrl: 'https://honeypot.io',
    bulletPoints: [
      'Migrate the leading platform to ReactJS, TypeScript, and GraphQL by progressively replacing the old stack, increasing developer productivity and web performance',
      "Work with the team to build a new application that's easy to use and maintain",
    ],
  },
  {
    company: 'Fitogram',
    position: 'Frontend Developer',
    timeRange: 'July 2018 - September 2021',
    companyUrl: 'https://fitogram.pro',
    bulletPoints: [
      'Reduce errors of the booking system by improving code quality and introducing the right architecture and technologies',
      'Implement the redesign of the booking system and new features',
    ],
  },
  {
    company: 'Finametrix',
    position: 'Fullstack Developer',
    timeRange: 'May 2017 - April 2018',
    companyUrl: 'https://web.archive.org/web/20210512054554/https://finametrix.com/',
    bulletPoints: [
      "Learn the technologies and tools that are used in the company's projects (MERN stack)",
      'Take ownership of smaller projects and develop new features',
    ],
  },
];

export const Experience = () => {
  const [activeEntry, setActiveEntry] = useState(0);
  const currentEntry = experienceEntries[activeEntry];
  return (
    <div className="flex gap-16 flex-col">
      <div className="article">
        <h2 className="text-center">Work experience</h2>
      </div>
      <div className="flex flex-column flex-wrap sm:flex-nowrap gap-12 m-auto w-full max-w-5xl">
        <div className="w-full sm:w-2/3 rounded-lg px-6 flex-grow py-6 bg-slate-700">
          <div className="article">
            <h3>
              {currentEntry.position} @
              <a href={currentEntry.companyUrl} target="blank">
                {currentEntry.company}
              </a>
            </h3>
            <p>{currentEntry.timeRange}</p>
            <ul>
              {currentEntry.bulletPoints?.map((bulletPoint) => (
                <li key={bulletPoint}>{bulletPoint}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="w-full sm:w-fit flex">
          <List
            className="mr-auto mb-auto"
            items={experienceEntries.map((entry, index) => ({
              active: index === activeEntry,
              title: <h4 className="text-lg">{entry.company}</h4>,
              onClick: () => setActiveEntry(index),
            }))}
          />
        </div>
      </div>
    </div>
  );
};
