import React from 'react';
import { Layout } from '../../components/Layout';
import { AboutMe } from '../../components/AboutMe';
import { Experience } from './Experience';
import { ConnectionLine } from '../../components/ConnectionLine';

const About = () => {
  return (
    <Layout title="About">
      <div className="flex m-auto px-4 sm:px-6 flex-col py-32 max-w-7xl">
        <div className="article ">
          <h1>About</h1>
        </div>
        <AboutMe horizontalPicture hideTitle />
        <ConnectionLine className="mb-16 lg:mb-32" />
        <Experience />
      </div>
    </Layout>
  );
};

export default About;
