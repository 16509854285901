import React, { ReactNode } from 'react';
import { Link } from 'gatsby';

interface Item {
  title: ReactNode | string;
  active: boolean;
  link?: string;
  onClick?: () => void;
}

const getActiveClassName = (isActive: boolean) =>
  isActive
    ? 'text-blue-400 hover:text-blue-300 border-current'
    : 'text-gray-400 hover:text-gray-300';

export const List = ({ items, className }: { items: Item[]; className?: string }) => {
  return (
    <ul className={`border-l border-slate-700 text-sm space-y-2 ${className}`}>
      {items.map((item) => {
        const currentClassName = `block border-l border-transparent hover:border-current pl-4 -ml-px ${getActiveClassName(
          item.active,
        )}`;

        return (
          <li className="">
            {item.link ? (
              <Link className={currentClassName} to={item.link}>
                {item.title}
              </Link>
            ) : (
              <button className={currentClassName} onClick={item.onClick}>
                {item.title}
              </button>
            )}
          </li>
        );
      })}
    </ul>
  );
};
